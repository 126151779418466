import oauth2 from '../../../global/components/base/oauth2';

function get_option_string(obj, iso_code) {
    var html = '';
    $.each(obj, function (i, n) {
        html += '<optgroup label="' + i + '">';
        $.each(n, function (j, country) {
            if (iso_code && iso_code == country.iso_code) {
                html += '<option country_id=' + country.country_id + ' data-icon="flag-' + country.iso_code.toLowerCase() + '" selected="selected" value=' + country.country_code + ' country_code=' + country.country_code + '>' + country.name + '(' + country.country_code + ')</option>';
            } else {
                html += '<option country_id=' + country.country_id + ' data-icon="flag-' + country.iso_code.toLowerCase() + '" value=' + country.country_code + ' country_code=' + country.country_code + '>' + country.name + '(' + country.country_code + ')</option>';
            }
        })
        html += '</optgroup>';
    })
    return html;
}
$(function () {
    oauth2({
        // facebook: {
        //     listener: document.querySelector('#facebook')
        // },
        linkedin: {
            listener: document.querySelector('#linkedin'),
            site_id: 1
        }
    });
    $('#email').on('keyup', function () {
        var value = $(this).val().trim();
        var regex = /^[0-9 ]+$/;
        if (regex.test(value)) {
            $('.phone_code').show();
            $('.send-btn.send-phone-btn').attr('style', 'display:block')
            $('.send-btn.send-email-btn').attr('style', 'display:none')
        } else {
            $('.phone_code').hide();
            $('.send-btn.send-phone-btn').attr('style', 'display:none')
            $('.send-btn.send-email-btn').attr('style', 'display:block')
        };
    });
    let lang = 'en-us';
    let country = "{$_SESSION['MY_COUNTRY_CODE'] ?? ''}";
    let init_iso_code = country ? country.toUpperCase() : '';
    $.getJSON('/common/data/country/' + lang + '.json', function (data, status, x) {
        if (status == 'success') {
            let html = '';
            let hot_country_en = { "Where are you from?": [{ "country_id": "1045", "iso_code": "CN", "country_code": "86", "name": "China (Mainland)" }, { "country_id": "1228", "iso_code": "US", "country_code": "1", "name": "United States" }, { "country_id": "1226", "iso_code": "GB", "country_code": "44", "name": "United Kingdom" }, { "country_id": "1039", "iso_code": "CA", "country_code": "1", "name": "Canada" }, { "country_id": "1082", "iso_code": "DE", "country_code": "49", "name": "Germany" }, { "country_id": "1076", "iso_code": "FR", "country_code": "33", "name": "France" }, { "country_id": "1107", "iso_code": "IT", "country_code": "39", "name": "Italy" }, { "country_id": "1098", "iso_code": "HK", "country_code": "852", "name": "Hong Kong, China" }, { "country_id": "1101", "iso_code": "IN", "country_code": "91", "name": "India" }, { "country_id": "1109", "iso_code": "JP", "country_code": "81", "name": "Japan" }, { "country_id": "1115", "iso_code": "KR", "country_code": "82", "name": "South Korea" }] };
            html += get_option_string(hot_country_en, init_iso_code);
            html += get_option_string(data, init_iso_code);
            $('#country').html(html);
            $("#country").selectBoxIt({});
        }
    });

    $(".select-login-type>li").on('click', function () {
        var type = $(this).attr('data-type');
        $(this).addClass('active');
        $(this).siblings().removeClass('active');
        if (type == 'account') {
            $(".password-div").show();
            $(".vcode-div").hide();
            $('#verifyCode').removeAttr("va-required");
            if (!$("#password")[0].hasAttribute('va-required')) {
                $("#password").attr('va-required', 'Please enter password');
            };
            $("input[name='login_type']").val('password');
        }
        if (type == 'verifyCode') {
            $(".password-div").hide();
            $(".vcode-div").show();
            $("#password").removeAttr("va-required");
            if (!$("#verifyCode")[0].hasAttribute('va-required')) {
                $("#verifyCode").attr('va-required', 'Please enter verification code');
            };
            $("input[name='login_type']").val('vcode');
        };
    });
    var check_cookie = function check_cookie(c_name) {
        if (document.cookie.length > 0) {
            var c_start = document.cookie.indexOf(c_name + "=");
            var c_end
            if (c_start != -1) {
                c_start = c_start + c_name.length + 1;
                c_end = document.cookie.indexOf(";", c_start);
                if (c_end == -1) {
                    c_end = document.cookie.length;
                }
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return "";
    };
    var check_Login_cookie = function check_Login_cookie() {
        var email_expire_second = check_cookie('login_org_expire_second');
        if (email_expire_second) {
            time($('.send-btn'), email_expire_second)
        }
    };
    check_Login_cookie()
    function set_cookie(c_name, second) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + second * 1000);
        document.cookie = c_name + "=" + escape(second) + (second == null ? "" : ";expires=" + exdate.toGMTString());
    };
    function time(o, wait) {
        if (wait == 0) {
            o.removeAttr("disabled");
            o.html("Get verify code");
            wait = 60;
        } else {
            o.attr("disabled", true);
            o.html(wait + "S");
            wait--;
            set_cookie('login_org_expire_second', wait)
            setTimeout(function () {
                time(o, wait);
            }, 1000);
        }
    }

    $(".send-btn.send-email-btn").on('click', function () {
        var email = $("#email").val();
        if (email != '') {
            $('.send-btn.send-email-btn').attr('data-dialog-big', 'true')
        } else {
            return
        }
    });
    $(".send-btn.send-phone-btn").on('click', function () {
        var email = $("#email").val();
        if (email != '') {
            $('.send-btn.send-phone-btn').attr('data-dialog-big', 'true')
        } else {
            return
        }
    });
});